import * as React from "react"
import { Hero } from "../components/Hero/Hero"
import SEO from "../components/SEO"
import heroImage from '../images/hero/about.jpg'
import '../styles.scss'

const AboutPage = () => (
  <>
    <SEO title="About" />
    <Hero backgroundImage={heroImage}>
      <div className='container-blue'>
        <div className='container-content'>
          <p className='content-60'>Keith Sammels and Nicola Sammels have worked successfully for some of the world’s largest hotel, leisure and retail brands.  Their particular style of strategy, integrated marketing, project management and business building has achieved many accolades.</p>
          <p className='content-60'>Montana Concept exists to deliver key tasks, where dedication, experience, track record, advice and delivery are essential.</p>
          <h3 className='about-script'>Experience</h3>
        </div>
      </div>
    </Hero>
  </>
)

export default AboutPage